import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import DataPicker from "../../Components/DataPicker"
import Input from "../../Components/Input"
import Menu from "../Menu"
import Titulo from "../../Components/Titulo"
import image_clean from "../../Images/Buttons/clean.png"
import image_loading from "../../Images/spinner.gif";
import image_previous from "../../Images/Buttons/previous.png"
import image_refresh from "../../Images/refresh.png"
import image_search from "../../Images/Buttons/search.png"

const MentesPensam = ({databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [balance, setBalance] = useState(0)
    
  const [initialDateSearch, setInitialDateSearch] = useState(null)
  const [finalDateSearch, setFinalDateSearch] = useState(null)    
  const [internalCode, setInternalCode] = useState("")
  const [internalNumber, setInternalNumber] = useState("")
  const [type, setType] = useState(1)

  const [firstAcess, setFirstAcess] = useState(true)
  const [showMenu, setShowMenu] = useState(true)

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")  

  const [resultSearch, setResultSearch] = useState([])
  const [resultCharge, setResultCharge] = useState({})
 
  useEffect(() => {
    if (firstAcess){
      updateBalance()
      setFirstAcess(false)
    }
  }, [firstAcess])

  const cleanCharge = () => {
    setInternalCode("")
    setInternalNumber("")

    setResultCharge({})
  }  

  const cleanCharges = () => {
    setInitialDateSearch(null)
    setFinalDateSearch(null)

    setResultSearch([])
  }

  const formatNumeric = (value, decimals) => {
    if (!value) return "0.00"
    if (value === ".") return value = "0."
      
    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)
    const decimalsNumber = value.toString().match(regex)[0]
    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return "0"
    return parsed
  }  

  const searchCharge = () => {
    let returnJson = []

    if (internalCode === "") {
      alert("Interno inválido!")
      return
    }

    if (internalNumber === "") {
      alert("Número interno inválido!")
      return
    }

    setLoading("Aguarde buscando a cobrança...")
    setWarning("")
    setError("")

    let formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID + "} }")
    formData.append("JSON_NEW_2", "{ \"INTERNAL\": "+ internalCode +", \"NUMBER_INTERNAL\": \""+ internalNumber +"\" }")
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("TYPE", type)
    formData.append("SYSTEM", system)    

    axios.post(BASE_URL + "COMPANY_BANK/SearchBillet", formData) 
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
    ", \"ERROR\": \"Não foi possível pesquisar a cobrança. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setLoading("")
        return
      }
      else
      { 
        setResultCharge(returnJson.OBJECT)
        setLoading("")
      }
    })  
  }

  const searchChargeList = () => {
    let returnJson = []

    if (!validateDate(initialDateSearch)) {
      alert("Data inicial inválida!")
      return
    }
        
    if (!validateDate(finalDateSearch)) {
      alert("Data final inválida!")
      return
    }

    const initialPeriod = initialDateSearch.toLocaleString()
    const finalPeriod = finalDateSearch.toLocaleString()    

    setLoading("Aguarde buscando as cobranças...")
    setWarning("")
    setError("")

    let formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID + "} }")
    formData.append("INITIAL_DATE", initialPeriod)
    formData.append("FINAL_DATE", finalPeriod)    
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("TYPE", type)
    formData.append("SYSTEM", system)    

    axios.post(BASE_URL + "COMPANY_BANK/SearchBilletList", formData) 
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
    ", \"ERROR\": \"Não foi possível pesquisar as cobranças. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setLoading("")
        return
      }
      else
      { 
        if (returnJson.OBJECT !== null)
          setResultSearch(returnJson.OBJECT)

        setLoading("")
      }
    })
  }

  const setFieldSelected = () => {
  }  

  const updateBalance = () => {
    let returnJson = []

    setLoading("Atualizando o saldo...")
    setWarning("")
    setError("")

    let formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID + "} }")
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("TYPE", type)
    formData.append("SYSTEM", system)   

    axios.post(BASE_URL + "COMPANY_BANK/Balance", formData) 
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
    ", \"ERROR\": \"Não foi possível pesquisar o saldo. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setLoading("")
        return
      }
      else
      {
        setBalance(formatNumeric(returnJson.OBJECT, 2))
        setLoading("")
      }
    })
  }

  const validateDate = (value) => {
    let error = false;
    let date = "";
    if ((value !== null) && (value !== "")) date = value.toLocaleString()

    let ardt = new Array([]);
    const ExpReg = new RegExp("(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[012])/[12][0-9]{3}");
    ardt = date.split("/");

    if (date.search(ExpReg) === -1) error = true
    else if (((ardt[1] === 4) || (ardt[1] === 6) || (ardt[1] === 9) || (ardt[1] === 11)) && (ardt[0] > 30)) error = true
    else if (ardt[1] === 2) {
      if ((ardt[0] > 28) && ((ardt[2]%4) !== 0)) error = true;
      if ((ardt[0] > 29) && ((ardt[2]%4) === 0)) error = true;
    }

    if (error) return false;
    return true;
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="Mentes que Pensam"
            titleSearch=""
            showSearch={false}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

            { loading ?
                <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                  <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                  <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                </div>
              :
                <>
                  <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                    <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5,
                      marginTop: 10, marginLeft: 5, width: 147}} onClick={setReturn}>
                      <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                        paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                        <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                      </div>
                      <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                        <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                      </div>
                    </div>
                  </div>

                  <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                  </div>

                  <div style={{paddingLeft: 20, paddingRight: 20}}>
                    <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                      paddingLeft: 10}}>
                      { warning && 
                        <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                          marginBottom: 10}}>
                          <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                        </div> 
                      }

                      { error &&
                        <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                          marginBottom: 10}}>
                          <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                        </div>
                      }
                    </div>

                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: 10,
                      marginBottom: 10}}>
                      <div style={{fontWeight: 600}}>Saldo:</div>
                        <div>{balance}</div>
                      <div>
                        <img src={image_refresh} alt="Voltar" onClick={updateBalance} />
                      </div>
                    </div>

                    <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0} : {paddingLeft: 10, paddingRight: 10}}>
                      <Titulo description="Consulta das Cobranças" />                

                      <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0} : {paddingLeft: 10, paddingRight: 10}}>
                        <div style={showMenu ?
                            (width < 820) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                            : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}
                          :
                            (width < 520) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                            : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}}>

                          <div style={showMenu ?
                              (width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                              : (width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                              : (width < 740) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                              : {display: "flex", flexGrow: 1, flexDirection: "row"}
                            :
                              (width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                              : {display: "flex", flexGrow: 1, flexDirection: "row"}}>
                            <div style={showMenu ?
                                (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                : (width < 740) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                              :
                                (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                              <DataPicker
                                name="mentesPensamInitialDateSearch"
                                description="Emissão Inicial"
                                value={initialDateSearch}
                                require={false}
                                onSelect={setFieldSelected}
                                onChange={(event) => setInitialDateSearch(event)} 
                                intervalo={true}
                                inicial={true}
                                endDate={finalDateSearch} />
                            </div>
                            <div style={showMenu ?
                                (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                : (width < 740) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                              :
                                (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                              <DataPicker
                                name="mentesPensamFinalDateSearch"
                                description="Emissão Final"
                                value={finalDateSearch}
                                require={false}
                                onSelect={setFieldSelected}
                                onChange={(event) => setFinalDateSearch(event)} 
                                intervalo={true}
                                inicial={false}
                                startDate={initialDateSearch} />
                            </div>
                          </div>
                          <div style={showMenu ?
                              (width < 820) ? {display: "flex", flexGrow: 1, alignItems: "flex-end", justifyContent: "center", marginTop: 10,
                                marginBottom: 10}
                              : {display: "flex", flexGrow: 0, alignItems: "flex-end"}
                            :
                              (width < 520) ? {display: "flex", flexGrow: 1, alignItems: "flex-end", justifyContent: "center", marginTop: 10,
                                marginBottom: 10}                            
                              : {display: "flex", flexGrow: 0, alignItems: "flex-end"}}>
                            <div style={{marginLeft: 10}}>
                              <img 
                                style={{height: 36, width: 36}}
                                name="mentesPensamMentesPensam"
                                src={image_search} 
                                alt="Pesquisar"
                                onClick={searchChargeList} />
                            </div>
                            <div style={{marginLeft: 10}}>
                              <img
                                style={{height: 36, width: 36}}
                                name="mentesPensamCleanCharges"
                                src={image_clean} 
                                alt="Limpar" 
                                onClick={cleanCharges} />
                            </div>
                          </div>
                        </div>
                        
                        { resultSearch && 
                          resultSearch.length !== 0 &&
                          <>
                            <div style={(showMenu) ?
                                (width < 1380) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingTop: 4, paddingBottom: 4,
                                  marginTop: 4}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, paddingTop: 4, paddingBottom: 4, marginTop: 4}
                              :
                                (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingTop: 4, paddingBottom: 4,
                                  marginTop: 4 }
                                : {display: "flex", flexDirection: "row", flexGrow: 1, paddingTop: 4, paddingBottom: 4, marginTop: 4 }}>

                              <div style={showMenu ?
                                  (width < 1180) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
                                  : {display: "flex", flexGrow: 0, flexDirection: "row"}
                                : 
                                  (width < 910) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
                                  : {display: "flex", flexGrow: 0, flexDirection: "row"}}>

                                <div style={showMenu ?
                                    (width < 980) ? {display: "flex", flexGrow: 0, flexDirection : "column"}
                                    : {display: "flex", flexGrow: 0, flexDirection : "row"}
                                  : 
                                    (width < 730) ? {display: "flex", flexGrow: 0, flexDirection : "column"}
                                    : {display: "flex", flexGrow: 0, flexDirection : "row"}}>
                                      
                                  <div style={showMenu ?
                                      (width < 560) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
                                      : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "row"}
                                      : (width < 880) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
                                      : {display: "flex", flexGrow: 0, flexDirection: "row"}
                                    :
                                      (width < 560) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
                                      : {display: "flex", flexGrow: 0, flexDirection: "row"}}>
                                    <div style={showMenu ?
                                        (width < 560) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 880) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 980) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 5, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 5, minWidth: 110, maxWidth: 110}
                                      :
                                        (width < 560) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 730) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 5, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 5, minWidth: 110, maxWidth: 110}}>
                                          
                                        <label style={{fontWeight: 600}}>Interno</label>
                                      </div>
                                    <div style={showMenu ?
                                        (width < 560) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                        : (width < 880) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 980) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 210, maxWidth: 210}
                                      : 
                                        (width < 560) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 730) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 210, maxWidth: 210}}>
                                          
                                      <label style={{fontWeight: 600}}>Número Interno</label>
                                    </div>
                                  </div>

                                  <div style={showMenu ?
                                      (width < 450) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
                                      : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "row"}
                                      : (width < 760) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
                                      : {display: "flex", flexGrow: 0, flexDirection: "row"}
                                    :
                                      (width < 450) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
                                      : {display: "flex", flexGrow: 0, flexDirection: "row"}}>
                                    <div style={showMenu ?
                                        (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 760) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 980) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 5, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                      :
                                        (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 730) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 5, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                          
                                      <label style={{fontWeight: 600}}>Título</label>
                                    </div>
                                    <div style={showMenu ?
                                        (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                        : (width < 760) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 980) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 90, maxWidth: 90}
                                      :
                                        (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 730) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 90, maxWidth: 90}}>

                                      <label style={{fontWeight: 600}}>Emissão</label>
                                    </div>
                                  </div>
                                </div>

                                <div style={showMenu ?
                                    (width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                    : (width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                    : (width < 760) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                    : (width < 1180) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                    : {display: "flex", flexGrow: 0, flexDirection: "row"}
                                  :
                                    (width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                    : (width < 910) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                    : {display: "flex", flexGrow: 0, flexDirection: "row"}}>

                                  <div style={showMenu ?
                                      (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                      : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 5, minWidth: 180}
                                      : (width < 760) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                      : (width < 1180) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 5, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 90, maxWidth: 90}
                                    :
                                      (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                      : (width < 910) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 5, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 90, maxWidth: 90}}>

                                    <label style={{fontWeight: 600}}>Vencim.</label>
                                  </div>
                                  <div style={showMenu ?
                                      (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                      : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                      : (width < 760) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                      : (width < 1180) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}
                                    :
                                      (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                      : (width < 910) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}}>
                                        
                                    <label style={{fontWeight: 600}}>Vlr. Original</label>
                                  </div>
                                </div>
                              </div>

                              <div style={showMenu ?
                                  (width < 1380) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, minWidth: 180}
                                :  
                                  (width < 1080) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, minWidth: 180}}>
                                    
                                <label style={{fontWeight: 600}}>Situação</label>
                              </div>
                            </div>
                            <hr/>

                            { resultSearch.map(item => (
                              <div key={item.ID} style={(showMenu) ?
                                  (width < 1380) ? {display: "flex", flexDirection: "column", flexGrow: 1,
                                    backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5,
                                    borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 4, paddingBottom: 4, marginTop: 4 }
                                  : 
                                    {display: "flex", flexDirection: "row", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)",
                                    borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5,
                                    paddingTop: 4, paddingBottom: 4, marginTop: 4 }
                                :
                                  (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 1,
                                    backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5,
                                    borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 4, paddingBottom: 4, marginTop: 4 }
                                  :
                                    {display: "flex", flexDirection: "row", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)",
                                    borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5,
                                    paddingTop: 4, paddingBottom: 4, marginTop: 4 }}>

                                <div style={showMenu ?
                                    (width < 1180) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
                                    : {display: "flex", flexGrow: 0, flexDirection: "row"}
                                  : 
                                    (width < 910) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
                                    : {display: "flex", flexGrow: 0, flexDirection: "row"}}>

                                  <div style={showMenu ?
                                      (width < 980) ? {display: "flex", flexGrow: 0, flexDirection : "column"}
                                      : {display: "flex", flexGrow: 0, flexDirection : "row"}
                                    : 
                                      (width < 730) ? {display: "flex", flexGrow: 0, flexDirection : "column"}
                                      : {display: "flex", flexGrow: 0, flexDirection : "row"}}>
                                        
                                    <div style={showMenu ?
                                        (width < 560) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
                                        : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "row"}
                                        : (width < 880) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
                                        : {display: "flex", flexGrow: 0, flexDirection: "row"}
                                      :
                                        (width < 560) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
                                        : {display: "flex", flexGrow: 0, flexDirection: "row"}}>
                                      <div style={showMenu ?
                                          (width < 560) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 5, minWidth: 180}
                                          : (width < 880) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                          : (width < 980) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 5, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, marginLeft: 5, minWidth: 110, maxWidth: 110}
                                        :
                                          (width < 560) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                          : (width < 730) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 5, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, marginLeft: 5, minWidth: 110, maxWidth: 110}}>
                                        {item.ID_BILLET}
                                      </div>
                                      <div style={showMenu ?
                                          (width < 560) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                          : (width < 880) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                          : (width < 980) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 210, maxWidth: 210}
                                        : 
                                          (width < 560) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                          : (width < 730) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 210, maxWidth: 210}}>
                                        {item.CLIENT_TITLE_NUMER}
                                      </div>
                                    </div>

                                    <div style={showMenu ?
                                        (width < 450) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
                                        : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "row"}
                                        : (width < 760) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
                                        : {display: "flex", flexGrow: 0, flexDirection: "row"}
                                      :
                                        (width < 450) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
                                        : {display: "flex", flexGrow: 0, flexDirection: "row"}}>
                                      <div style={showMenu ?
                                          (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 5, minWidth: 180}
                                          : (width < 760) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                          : (width < 980) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 5, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                        :
                                          (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                          : (width < 730) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 5, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                        {item.BENEFICIARY_NUMBER_TITLE}
                                      </div>
                                      <div style={showMenu ?
                                          (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                          : (width < 760) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                          : (width < 980) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 90, maxWidth: 90}
                                        :
                                          (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                          : (width < 730) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 90, maxWidth: 90}}>
                                        { String(item.DATE_ISSUE_BILLING_TITLE).substr(8,2) + "/" +
                                          String(item.DATE_ISSUE_BILLING_TITLE).substr(5,2) + "/" +
                                            String(item.DATE_ISSUE_BILLING_TITLE).substr(0,4) }
                                      </div>
                                    </div>
                                  </div>

                                  <div style={showMenu ?
                                      (width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                      : (width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                      : (width < 760) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                      : (width < 1180) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                      : {display: "flex", flexGrow: 0, flexDirection: "row"}
                                    :
                                      (width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                      : (width < 910) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                      : {display: "flex", flexGrow: 0, flexDirection: "row"}}>

                                    <div style={showMenu ?
                                        (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 760) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 1180) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 5, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 90, maxWidth: 90}
                                      :
                                        (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 910) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 5, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 90, maxWidth: 90}}>
                                      { String(item.DATE_DUE_BILLING_TITLE).substr(8,2) + "/" +
                                        String(item.DATE_DUE_BILLING_TITLE).substr(5,2) + "/" +
                                          String(item.DATE_DUE_BILLING_TITLE).substr(0,4) }
                                    </div>
                                    <div style={showMenu ?
                                        (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                        : (width < 760) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 1180) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}
                                      :
                                        (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 910) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}}>
                                      {formatNumeric(item.ORIGINAL_VALUE_BILLING_TITLE,2)}
                                    </div>
                                  </div>
                                </div>

                                <div style={showMenu ?
                                    (width < 1380) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, minWidth: 180}
                                  :  
                                    (width < 1080) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, minWidth: 180}}>{item.STATUS_BILLING_TITLE}
                                </div>
                              </div>
                            ))}
                          </>
                        }

                        { resultSearch && 
                          resultSearch.length === 0 &&
                            <div style={{display: "flex", flexGrow: 1, marginTop: 10, marginBottom: 10, justifyContent: "center"}}>
                              <label style={{fontWeight: 600}}>Nenhum registro encontrado.</label>
                            </div>
                        }
                      </div>
                    </div>

                    <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0} : {paddingLeft: 10, paddingRight: 10}}>
                      <Titulo description="Consulta a Cobrança" />

                      <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0} : {paddingLeft: 10, paddingRight: 10}}>
                        <div style={showMenu ?
                            (width < 820) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                            : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}
                          :
                            (width < 520) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                            : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}}>

                          <div style={showMenu ?
                              (width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                              : (width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                              : (width < 740) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                              : {display: "flex", flexGrow: 1, flexDirection: "row"}
                            :
                              (width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                              : {display: "flex", flexGrow: 1, flexDirection: "row"}}>
                            <div style={showMenu ?
                                (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                : (width < 740) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                              :
                                (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>

                              <Input
                                type="text"
                                name="mentesPensamInternal"
                                description="Interno"
                                value={internalCode}
                                maxLength={11}
                                disabled={false}
                                require={false}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setInternalCode(event.target.value)} />
                            </div>
                            <div style={showMenu ?
                                (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                : (width < 740) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                              :
                                (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>

                              <Input
                                type="text"
                                name="mentesPensamInternalNumber"
                                description="Número Interno"
                                value={internalNumber}
                                maxLength={20}
                                disabled={false}
                                require={false}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setInternalNumber(event.target.value)} />
                            </div>
                          </div>
                          <div style={showMenu ?
                              (width < 820) ? {display: "flex", flexGrow: 1, alignItems: "flex-end", justifyContent: "center", marginTop: 10,
                                marginBottom: 10}
                              : {display: "flex", flexGrow: 0, alignItems: "flex-end"}
                            :
                              (width < 520) ? {display: "flex", flexGrow: 1, alignItems: "flex-end", justifyContent: "center", marginTop: 10,
                                marginBottom: 10}                            
                              : {display: "flex", flexGrow: 0, alignItems: "flex-end"}}>
                            <div style={{marginLeft: 10}}>
                              <img 
                                style={{height: 36, width: 36}}
                                name="mentesPensamMentesPensam"
                                src={image_search} 
                                alt="Pesquisar"
                                onClick={searchCharge} />
                            </div>
                            <div style={{marginLeft: 10}}>
                              <img
                                style={{height: 36, width: 36}}
                                name="mentesPensamCleanCharges"
                                src={image_clean} 
                                alt="Limpar" 
                                onClick={cleanCharge} />
                            </div>
                          </div>
                        </div>

                        { (resultCharge.ID_BILLET !== undefined) &&
                          <>
                            <div style={(showMenu) ?
                                (width < 1380) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingTop: 4, paddingBottom: 4,
                                  marginTop: 4}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, paddingTop: 4, paddingBottom: 4, marginTop: 4}
                              :
                                (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingTop: 4, paddingBottom: 4,
                                  marginTop: 4 }
                                : {display: "flex", flexDirection: "row", flexGrow: 1, paddingTop: 4, paddingBottom: 4, marginTop: 4 }}>

                              <div style={showMenu ?
                                  (width < 1180) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
                                  : {display: "flex", flexGrow: 0, flexDirection: "row"}
                                : 
                                  (width < 910) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
                                  : {display: "flex", flexGrow: 0, flexDirection: "row"}}>

                                <div style={showMenu ?
                                    (width < 980) ? {display: "flex", flexGrow: 0, flexDirection : "column"}
                                    : {display: "flex", flexGrow: 0, flexDirection : "row"}
                                  : 
                                    (width < 730) ? {display: "flex", flexGrow: 0, flexDirection : "column"}
                                    : {display: "flex", flexGrow: 0, flexDirection : "row"}}>
                                      
                                  <div style={showMenu ?
                                      (width < 560) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
                                      : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "row"}
                                      : (width < 880) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
                                      : {display: "flex", flexGrow: 0, flexDirection: "row"}
                                    :
                                      (width < 560) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
                                      : {display: "flex", flexGrow: 0, flexDirection: "row"}}>
                                    <div style={showMenu ?
                                        (width < 560) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 880) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 980) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 5, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 5, minWidth: 110, maxWidth: 110}
                                      :
                                        (width < 560) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 730) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 5, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 5, minWidth: 110, maxWidth: 110}}>
                                          
                                        <label style={{fontWeight: 600}}>Interno</label>
                                      </div>
                                    <div style={showMenu ?
                                        (width < 560) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                        : (width < 880) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 980) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 210, maxWidth: 210}
                                      : 
                                        (width < 560) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 730) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 210, maxWidth: 210}}>
                                          
                                      <label style={{fontWeight: 600}}>Número Interno</label>
                                    </div>
                                  </div>

                                  <div style={showMenu ?
                                      (width < 450) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
                                      : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "row"}
                                      : (width < 760) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
                                      : {display: "flex", flexGrow: 0, flexDirection: "row"}
                                    :
                                      (width < 450) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
                                      : {display: "flex", flexGrow: 0, flexDirection: "row"}}>
                                    <div style={showMenu ?
                                        (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 760) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 980) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 5, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                      :
                                        (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 730) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 5, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                          
                                      <label style={{fontWeight: 600}}>Título</label>
                                    </div>
                                    <div style={showMenu ?
                                        (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                        : (width < 760) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 980) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 90, maxWidth: 90}
                                      :
                                        (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 730) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 90, maxWidth: 90}}>

                                      <label style={{fontWeight: 600}}>Emissão</label>
                                    </div>
                                  </div>
                                </div>

                                <div style={showMenu ?
                                    (width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                    : (width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                    : (width < 760) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                    : (width < 1180) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                    : {display: "flex", flexGrow: 0, flexDirection: "row"}
                                  :
                                    (width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                    : (width < 910) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                    : {display: "flex", flexGrow: 0, flexDirection: "row"}}>

                                  <div style={showMenu ?
                                      (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                      : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 5, minWidth: 180}
                                      : (width < 760) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                      : (width < 1180) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 5, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 90, maxWidth: 90}
                                    :
                                      (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                      : (width < 910) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 5, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 90, maxWidth: 90}}>

                                    <label style={{fontWeight: 600}}>Vencim.</label>
                                  </div>
                                  <div style={showMenu ?
                                      (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                      : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                      : (width < 760) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                      : (width < 1180) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}
                                    :
                                      (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                      : (width < 910) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}}>
                                        
                                    <label style={{fontWeight: 600}}>Vlr. Original</label>
                                  </div>
                                </div>
                              </div>

                              <div style={showMenu ?
                                  (width < 1380) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, minWidth: 180}
                                :  
                                  (width < 1080) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, minWidth: 180}}>
                                    
                                <label style={{fontWeight: 600}}>Situação</label>
                              </div>
                            </div>
                            <hr/>

                            <div style={(showMenu) ?
                                (width < 1380) ? {display: "flex", flexDirection: "column", flexGrow: 1,
                                  backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5,
                                  borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 4, paddingBottom: 4, marginTop: 4 }
                                : 
                                  {display: "flex", flexDirection: "row", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)",
                                  borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5,
                                  paddingTop: 4, paddingBottom: 4, marginTop: 4 }
                              :
                                (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 1,
                                  backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5,
                                  borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 4, paddingBottom: 4, marginTop: 4 }
                                :
                                  {display: "flex", flexDirection: "row", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)",
                                  borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5,
                                  paddingTop: 4, paddingBottom: 4, marginTop: 4 }}>

                              <div style={showMenu ?
                                  (width < 1180) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
                                  : {display: "flex", flexGrow: 0, flexDirection: "row"}
                                : 
                                  (width < 910) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
                                  : {display: "flex", flexGrow: 0, flexDirection: "row"}}>

                                <div style={showMenu ?
                                    (width < 980) ? {display: "flex", flexGrow: 0, flexDirection : "column"}
                                    : {display: "flex", flexGrow: 0, flexDirection : "row"}
                                  : 
                                    (width < 730) ? {display: "flex", flexGrow: 0, flexDirection : "column"}
                                    : {display: "flex", flexGrow: 0, flexDirection : "row"}}>
                                      
                                  <div style={showMenu ?
                                      (width < 560) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
                                      : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "row"}
                                      : (width < 880) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
                                      : {display: "flex", flexGrow: 0, flexDirection: "row"}
                                    :
                                      (width < 560) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
                                      : {display: "flex", flexGrow: 0, flexDirection: "row"}}>
                                    <div style={showMenu ?
                                        (width < 560) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 880) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 980) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 5, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 5, minWidth: 110, maxWidth: 110}
                                      :
                                        (width < 560) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 730) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 5, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 5, minWidth: 110, maxWidth: 110}}>

                                      {resultCharge.ID_BILLET}
                                    </div>
                                    <div style={showMenu ?
                                        (width < 560) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                        : (width < 880) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 980) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 210, maxWidth: 210}
                                      : 
                                        (width < 560) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 730) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 210, maxWidth: 210}}>
                                      {resultCharge.CLIENT_TITLE_NUMER}
                                    </div>
                                  </div>

                                  <div style={showMenu ?
                                      (width < 450) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
                                      : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "row"}
                                      : (width < 760) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
                                      : {display: "flex", flexGrow: 0, flexDirection: "row"}
                                    :
                                      (width < 450) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
                                      : {display: "flex", flexGrow: 0, flexDirection: "row"}}>
                                    <div style={showMenu ?
                                        (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 760) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 980) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 5, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                      :
                                        (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 730) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 5, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                      {resultCharge.BENEFICIARY_NUMBER_TITLE}
                                    </div>
                                    <div style={showMenu ?
                                        (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                        : (width < 760) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 980) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 90, maxWidth: 90}
                                      :
                                        (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                        : (width < 730) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 90, maxWidth: 90}}>
                                      { String(resultCharge.DATE_ISSUE_BILLING_TITLE).substr(8,2) + "/" +
                                        String(resultCharge.DATE_ISSUE_BILLING_TITLE).substr(5,2) + "/" +
                                          String(resultCharge.DATE_ISSUE_BILLING_TITLE).substr(0,4) }
                                    </div>
                                  </div>
                                </div>

                                <div style={showMenu ?
                                    (width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                    : (width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                    : (width < 760) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                    : (width < 1180) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                    : {display: "flex", flexGrow: 0, flexDirection: "row"}
                                  :
                                    (width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                    : (width < 910) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                    : {display: "flex", flexGrow: 0, flexDirection: "row"}}>

                                  <div style={showMenu ?
                                      (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                      : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 5, minWidth: 180}
                                      : (width < 760) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                      : (width < 1180) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 5, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 90, maxWidth: 90}
                                    :
                                      (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                      : (width < 910) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 5, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 90, maxWidth: 90}}>
                                    { String(resultCharge.DATE_DUE_BILLING_TITLE).substr(8,2) + "/" +
                                      String(resultCharge.DATE_DUE_BILLING_TITLE).substr(5,2) + "/" +
                                        String(resultCharge.DATE_DUE_BILLING_TITLE).substr(0,4) }
                                  </div>
                                  <div style={showMenu ?
                                      (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                      : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                      : (width < 760) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                      : (width < 1180) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}
                                    :
                                      (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                      : (width < 910) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}}>
                                    {formatNumeric(resultCharge.ORIGINAL_VALUE_BILLING_TITLE,2)}
                                  </div>
                                </div>
                              </div>

                              <div style={showMenu ?
                                  (width < 1380) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, minWidth: 180}
                                :  
                                  (width < 1080) ? {display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, minWidth: 180}}>{resultCharge.STATUS_BILLING_TITLE}
                              </div>
                            </div>
                          </>
                        }
              
                        { (resultCharge.ID_BILLET === undefined) &&
                          <div style={{display: "flex", flexGrow: 1, marginTop: 10, marginBottom: 10, justifyContent: "center"}}>
                            <label style={{fontWeight: 600}}>Nenhum registro encontrado.</label>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </>
          }

        </div>
      </div>
      
      <Footer/>
    </div>
  )
}

export default MentesPensam