import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../../Util/requests"
import DataPicker from "../../../Components/DataPicker"
import Input from "../../../Components/Input"
import InputNumeric from "../../../Components/InputNumeric"
import SearchPagination from "../../../Components/SearchPagination"
import Select from "../../../Components/Select"
import Titulo from "../../../Components/Titulo"
import image_add from "../../../Images/Buttons/add.png"
import image_clean from "../../../Images/Buttons/clean.png"
import image_loading from "../../../Images/spinner.gif"
import image_previous from "../../../Images/Buttons/previous.png"
import image_search from "../../../Images/Buttons/search.png"
import image_select from "../../../Images/Buttons/select_circle.png"

const SearchNoteService = ({databaseType, databasePath, jsonLogin, jsonCompany, system, setSelected, setReturn, showMenu, width}) => {
  const [billReceiveCode, setBillReceiveCode] = useState("")
  const [number, setNumber] = useState("")
  const [series, setSeries] = useState("")
  const [value, setValue] = useState("")
  const [situation, setSituation] = useState(0)

  const [clientCorporateName, setClientCorporateName] = useState("")
  const [clientCorporateNameSelected, setClientCorporateNameSelected] = useState("")
  const [resultClient, setResultClient] = useState({})
  const [resultClientFilter, setResultClientFilter] = useState({})

  const [iniDate, setIniDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [transmissionDateIni, setTransmissionDateIni] = useState(null)
  const [transmissionDateEnd, setTransmissionDateEnd] = useState(null)

  const [showClient, setShowClient] = useState(false)
  const [showResult, setShowResult] = useState(false)

  const [result, setResult] = useState({})
  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [optionsRegPage, setOptionsRegPage] = useState("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
    ", {\"ID\": 600, \"VALUE\": \"600\" }]}")

  const [updatePage, setUpdatePage] = useState(false)
  const [pageCurrent, setPageCurrent] = useState(1)
  const [recordPage, setRecordPage] = useState(200)
  const [totalRecord, setTotalRecord] = useState(0)
  const [totalPage, setTotalPage] = useState(0)

  const [firstAcess, setFirstAcess] = useState(true)

  useEffect(() => {
    let returnJson = []

    if (firstAcess){
      ////
      // Get Client information // Carrega as informações dos clientes
      ////
      setLoading("Aguarde buscando as informações dos Clientes...")
      setWarning("")
      setError("")

      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", "{ \"BILL_RECEIVE\": { \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" } } }")
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "NOTE_SERVICE/SearchClient", formData) 
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar os Clientes. ("+ response +")\" }") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setLoading("")
          setWarning("")
          setError(returnJson.ERROR)
          return
        }

        let arrClient = []
        returnJson.OBJECT &&
        returnJson.OBJECT.map(item => (
            arrClient.push(item)
          ))
        setResultClient(arrClient)

        let arrClientFilter = []
        returnJson.OBJECT &&
          returnJson.OBJECT.map(item => (
            arrClientFilter.push(item)
          ))
        setResultClientFilter(arrClientFilter)
      })

      setFirstAcess(false)
      setLoading("")
    }

    if (updatePage) search()

    if (showClient){
      let arrClient = []
  
      if (clientCorporateName === ""){
        resultClient.map(item => (
          arrClient.push(item)
        ))
      }
      else{
        resultClient.map(item => (
          (item.CORPORATE_NAME.indexOf(clientCorporateName.toUpperCase()) !== -1) &&
            arrClient.push(item)
        ))
      }
      setResultClientFilter(arrClient)
    }

  }, [databaseType, databasePath, jsonLogin, jsonCompany, system, updatePage, clientCorporateName])

  const addClient = () => {
    cleanClient()
    setShowClient(true)
  }

  const clean = () => {
    setBillReceiveCode("")
    setNumber("")
    setSeries("")    
    setValue("")
    setSituation(0)
  
    setClientCorporateName("")
    setClientCorporateNameSelected("")

    setResultClientFilter(resultClient)
  
    setIniDate(null)
    setEndDate(null)
    setTransmissionDateIni(null)
    setTransmissionDateEnd(null)

    setUpdatePage(updatePage + 1)
  }

  const cleanClient = () => {
    resultClient.map(item => (
      item.MARKET = false
    ))

    setResultClientFilter(resultClient)

    setClientCorporateNameSelected("")
    setClientCorporateName("")
    setShowClient(false)
  }

  const formatNumeric = (value, decimals) => {
    if (!value) return "0.00"
    if (value === ".") return value = "0."

    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)

    let decimalsNumber = 0
    try { decimalsNumber = value.toString().match(regex)[0] } catch { decimalsNumber = 0 }

    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return "0.00"
    return parsed
  }

  const handleValue = (event) => {
    setValue(formatNumeric(event.target.value, 2))
  }

  const handleRecordPage = (event) => {
    if (totalRecord > 0) {
      setPageCurrent(1)
      setRecordPage(event)
      setUpdatePage(updatePage + 1)
    }
  }

  const markAllClient = () => {
    let selected = ""

    resultClient.map(item => (
      (selected === "") ? selected = (item.CPF_CNPJ + "-" + item.CORPORATE_NAME)
      : selected += ("," + item.CPF_CNPJ + "-" + item.CORPORATE_NAME)
    ))
    
    setClientCorporateNameSelected(selected)
    setShowClient(false)
  }

  const pageNext = () => {
    if (totalRecord > 0) {
      if (pageCurrent < totalPage) {
        setPageCurrent(pageCurrent + 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  const pagePrevious = () => {
    if (totalRecord > 0) {
      if (pageCurrent !== 1) {
        setPageCurrent(pageCurrent - 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  const search = () => {
    let returnJson = []

    let search = false
    if (number !== "") search = true
    if (!search) if (billReceiveCode !== "") search = true
    if (!search) if (series !== "") search = true
    if (!search) if (value !== "") search = true
    if (!search) if (clientCorporateNameSelected !== "") search = true
    if (!search) if (situation !== "") if (situation !== 0) if (situation !== "0") search = true
    if (!search) if ((validateDate(iniDate)) && (validateDate(endDate))) search = true
    if (!search) if ((validateDate(transmissionDateIni)) && (validateDate(transmissionDateEnd))) search = true

    if (search){
      setLoading("Aguarde buscando as informações...")
      setWarning("")
      setError("")

      let jNoteService = "{"
      jNoteService += " \"BILL_RECEIVE\": { \"COMPANY\": { \"ID\": "+ jsonCompany.ID + " }"
      if (billReceiveCode !== "") jNoteService += ", \"ID\": "+ billReceiveCode
      jNoteService += "} "
      if (clientCorporateNameSelected !== "") jNoteService += ", \"CLIENTS\": \""+ clientCorporateNameSelected +"\""
      if (number !== "") jNoteService += ", \"NUMBER\": \""+ number + "\""
      if (series !== "") jNoteService += ", \"SERIES\": \""+ series + "\""
      if (value !== "") jNoteService += ", \"VALUE\": "+ value

      if (validateDate(iniDate) && validateDate(endDate)){
        const emissionI = iniDate.toLocaleString()
        const emissionE = endDate.toLocaleString()

        const emissionIni = String(emissionI).substr(6,4) + "-" + String(emissionI).substr(3,2) + "-" + String(emissionI).substr(0,2) + "T00:00:00";
        const emissionEnd = String(emissionE).substr(6,4) + "-" + String(emissionE).substr(3,2) + "-" + String(emissionE).substr(0,2) + "T00:00:00";
        jNoteService += ", \"DATE_START\": \""+ emissionIni + "\""
        jNoteService += ", \"DATE_END\": \""+ emissionEnd + "\""
      }

      if (validateDate(transmissionDateIni) && validateDate(transmissionDateEnd)){
        const transmissionI = transmissionDateIni.toLocaleString()
        const transmissionE = transmissionDateEnd.toLocaleString()

        const transmissionIni = String(transmissionI).substr(6,4) + "-" + String(transmissionI).substr(3,2) + "-" + String(transmissionI).substr(0,2) +
          "T00:00:00";

        const transmissionEnd = String(transmissionE).substr(6,4) + "-" + String(transmissionE).substr(3,2) + "-" + String(transmissionE).substr(0,2) +
          "T00:00:00";

        jNoteService += ", \"TRANSMISSION_DATE_START\": \""+ transmissionIni + "\""
        jNoteService += ", \"TRANSMISSION_DATE_END\": \""+ transmissionEnd + "\""
      }

      if (situation !== "") if (situation !== "0") jNoteService += ", \"SITUATION\": "+ situation
      jNoteService += " }"

      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jNoteService)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "NOTE_SERVICE/TotalRecords", formData) 
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar o total de Notas de Serviço. ("+ response +")\" }") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setWarning("")
          setError(returnJson.ERROR)

          setShowClient(false)
          setShowResult(false)
          setLoading("")
          return
        }

        const tRecord = returnJson.OBJECT
        if (tRecord === 0)
        {
          setWarning("Nenhum registro encontrado.")
          setError("")

          setShowClient(false)
          setShowResult(false)        
          setLoading("")
          return
        }

        const operation = tRecord / recordPage
        const numberR = Math.floor(operation)
        const decimal = operation % 1

        let tPage = 0
        if (decimal > 0) tPage = numberR + 1
        else tPage = numberR

        if (tRecord > 600)
          setOptionsRegPage("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
            ", {\"ID\": 600, \"VALUE\": \"600\" }, {\"ID\": "+ tRecord +", \"VALUE\": \"Total: "+ tRecord +"\" }]}")

        setTotalRecord(tRecord)
        setTotalPage(tPage)
        if (tPage < pageCurrent) setPageCurrent(1)

        returnJson.WARNING && setWarning(returnJson.WARNING)
      
        let qtdeInitial = 0
        if (pageCurrent > 1) qtdeInitial = ((pageCurrent - 1) * recordPage)

        formData = new FormData()
        formData.append("DATABASE_TYPE", databaseType)
        formData.append("DATABASE_PATH", databasePath)
        formData.append("JSON_NEW_1", jNoteService)
        formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
        formData.append("SYSTEM", system)
        formData.append("QUANTITY_INITIAL", qtdeInitial)
        formData.append("QUANTITY_RECORDS", recordPage)

        axios.post(BASE_URL + "NOTE_SERVICE/Search", formData)
        .then((response) => { returnJson = JSON.parse(response.data) })
        .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
          ", \"ERROR\": \"Não foi possível pesquisar as Notas Fiscais de Serviço. ("+ response +")\"}") })
        .finally(() => {
          if (returnJson.SUCCESS === false){
            setWarning("")
            setError(returnJson.ERROR)

            setShowClient(false)
            setShowResult(false)
            setLoading("") 
            return
          }
           
          setShowClient(false)

          setResult(returnJson)
          setShowResult(true)
          setLoading("")
        })
      })
    }
    else{
      setLoading("")
      setWarning("")
      setError("Nenhum filtro informado!")

      setShowClient(false)
      setShowResult(false)
    }
  }

  const selectClient = () => {
    setShowClient(false)
  }

  const setFieldSelected = () => {
  }

  const updateShowClient = (id) => {
    resultClient.map(item => (
      (item.ID === id) ? item.MARKET = !item.MARKET : ""
    ))

    let selected = ""
    resultClient.map(item => (
      (item.MARKET) ? (selected === "") ? selected = item.CPF_CNPJ + "-" + item.CORPORATE_NAME
                       : selected = selected + "," + item.CPF_CNPJ + "-" + item.CORPORATE_NAME
      : ""
    ))
    setClientCorporateNameSelected(selected)
  }

  const validateDate = (value) => {
    let error = false;
    let date = "";
    if ((value !== null) && (value !== "")) date = value.toLocaleString()

    let ardt = new Array([]);
    const ExpReg = new RegExp("(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[012])/[12][0-9]{3}");
    ardt = date.split("/");

    if (date.search(ExpReg)===-1) error = true
    else if (((ardt[1]===4)||(ardt[1]===6)||(ardt[1]===9)||(ardt[1]===11))&&(ardt[0]>30)) 
      error = true
    else if (ardt[1]===2) {
      if ((ardt[0]>28)&&((ardt[2]%4)!==0)) error = true;
      if ((ardt[0]>29)&&((ardt[2]%4)===0)) error = true;
    }

    if (error) return false;
    return true;
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
      { loading ?
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems : "center"}}>
            <img style={{marginBottom: 10, width: 28, height: 28}} src={image_loading} alt="Carregando"/>
            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", marginTop: 15}}>{loading}</label>
          </div>
        :
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
            {/* Begin - Buttons // Botões */}
            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, marginLeft: 5,
                width: 147}} onClick={search}>
                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                  paddingBottom: 5, paddingLeft: 10}}>
                  <img style={{height: 32, width: 32}} src={image_search} alt="Pesquisar"/>
                </div>
                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Pesquisar</label>
                </div>
              </div>

              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, marginLeft: 5,
                width: 147}} onClick={clean}>
                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                  paddingBottom: 5, paddingLeft: 10}}>
                  <img style={{height: 32, width: 32}} src={image_clean} alt="Limpar"/>
                </div>
                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
                </div>
              </div>

              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, marginLeft: 5,
                width: 147}} onClick={setReturn}>
                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                  paddingBottom: 5, paddingLeft: 10}}>
                  <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                </div>
                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                </div>
              </div>
            </div>
            {/* End - Buttons // Botões */}
            
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
              <div style={{display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10}} />
            </div>

            <div style={{paddingLeft: 10, paddingRight: 10}}>
              { warning &&
                <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                  <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                </div>
              }

              { error &&
                <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                  <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                </div>
              }

              <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0} : {paddingLeft: 10, paddingRight: 10}}> 
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10, marginRight: 0,
                  marginBottom: 10, marginLeft: 0}}>
                  <label style={{fontWeight: 600, textAlign: "center"}}>CRITÉRIOS PARA PESQUISA</label>
                </div>

                <div style={(showMenu) ? 
                    (width < 1370) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                  : 
                    (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                  <div style={showMenu ?
                      {display: "flex", flexGrow: 1, minWidth: 180}
                    :
                      {display: "flex", flexGrow: 1, minWidth: 180}}>
                    <Input
                      type="search"
                      name="searchNoteServiceNumber"
                      description="Número"
                      value={number}
                      maxLength={50}
                      disabled={false}
                      require={false}
                      image={false}
                      typeInput={2}
                      onSelect={setFieldSelected}
                      onChange={(event) => setNumber(event.target.value)} />
                  </div>

                  <div style={showMenu ?
                      (width < 940) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                      : (width < 1370) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                    :
                      (width < 680) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                      : (width < 1080) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>

                    <div style={showMenu ?
                        (width < 940) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}
                      :
                        (width < 680) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}}>
                      <Input
                        type="search"
                        name="searchNoteServiceSeries"
                        description="Série"
                        value={series}
                        maxLength={5}
                        disabled={false}
                        require={false}
                        image={false}
                        typeInput={2}
                        onSelect={setFieldSelected}
                        onChange={(event) => setSeries(event.target.value)} />
                    </div>

                    <div style={showMenu ?
                        (width < 560) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                        : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                        : (width < 830) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                        : (width < 940) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                        : (width < 1370) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                      :
                        (width < 560) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                        : (width < 680) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                        : (width < 1080) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>

                      <div style={showMenu ?
                          (width < 560) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                          : (width < 830) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : (width < 1370) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                        :
                          (width < 560) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : (width < 1080) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                        <InputNumeric
                          type="text"
                          name="searchNoteServiceValue"
                          description="Valor"
                          value={value}
                          maxLength={13}
                          disabled={false}
                          require={false}
                          negative={false} 
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          onChange={handleValue} />
                      </div>
                      <div style={showMenu ?
                          (width < 560) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                          : (width < 830) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : (width < 1370) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                        :
                          (width < 560) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : (width < 1080) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 240, maxWidth: 240}}>
                        <Select
                          name="searchNoteServiceSituation"
                          description="Situação"
                          value={situation || ""}
                          require={false}
                          options={JSON.parse("{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}, {\"ID\": 1, \"VALUE\": \"Lançada\"}"+
                            ", {\"ID\": 2, \"VALUE\": \"Aguardando Retorno\"}, {\"ID\": 3, \"VALUE\": \"Transmitida\"}"+
                            ", {\"ID\": 4, \"VALUE\": \"Cancelada\"}]}")}
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          set={setSituation} />
                      </div>
                    </div>
                  </div>
                </div>

                <div style={(showMenu) ? 
                    (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                  : 
                    (width < 830) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                  <div style={showMenu ?
                      (width < 1100) ? {display: "flex", flexGrow: 1, minWidth: 180}
                      : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}
                    :
                      (width < 830) ? {display: "flex", flexGrow: 1, minWidth: 180}
                      : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}}>
                    <InputNumeric
                      type="text"
                      name="searchNoteServiceBillReceiveCode"
                      description="Conta a Receber"
                      value={billReceiveCode || ""}
                      maxLength={11}
                      disabled={false}
                      require={false}
                      negative={false} 
                      image={false}
                      typeInput={2}
                      onSelect={setFieldSelected}
                      onChange={(event) => setBillReceiveCode(event.target.value)} />
                  </div>
                  
                  <div style={(showMenu) ? 
                      (width < 930) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                      : (width < 1100) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                    : 
                      (width < 660) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                      : (width < 830) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>
                    <div style={{display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}}>
                      <Input
                        type="search"
                        name="searchNoteServiceClientCorporateNameSelected"
                        description="Clientes Selecionados"
                        value={clientCorporateNameSelected}
                        maxLength={6000}
                        disabled={true}
                        require={false}
                        image={false}
                        typeInput={2}
                        onSelect={setFieldSelected} />
                    </div>

                    <div style={(showMenu) ? 
                        (width < 930) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                      : 
                        (width < 660) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                      <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={(() => { addClient() })}>
                        <img style={{height: 42, width: 42}} src={image_add} alt="Adicionar"/>
                      </div>
                      <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={(() => { cleanClient() })}>
                        <img style={{height: 42, width: 42}} src={image_clean} alt="Limpar"/>
                      </div>
                    </div>
                  </div>
                </div>

                { showClient &&
                  <div style={{display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}}>
                    <div style={{display: "flex", flexGrow: 0}}>
                      <div style={{width: "100%"}}>
                        <Titulo description="Cliente" />
                      </div>
                    </div>

                    <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                      <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                        marginLeft: 5, width: 210}} onClick={() => selectClient()}>
                        <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                          paddingBottom: 5, paddingLeft: 10}}>
                          <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar"/>
                        </div>
                        <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                          <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar</label>
                        </div>
                      </div>

                      <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                        marginLeft: 5, width: 210}} onClick={() => markAllClient()}>
                        <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                          paddingBottom: 5, paddingLeft: 10}}>
                          <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar Todos"/>
                        </div>
                        <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                          <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar Todos</label>
                        </div>
                      </div>

                      <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                        marginLeft: 5, width: 210}} onClick={() => cleanClient()}>
                        <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                          paddingBottom: 5, paddingLeft: 10}}>
                          <img style={{height: 32, width: 32}} src={image_clean} alt="Limpar"/>
                        </div>
                        <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                          <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
                        </div>
                      </div>
                    </div>

                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <div style={{display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10}} />
                    </div>

                    <div style={(width < 450) ? {paddingLeft: 5, paddingRight: 5} : {paddingLeft: 10, paddingRight: 10}}>
                      <div style={{display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}}>
                        <Input
                          type="search"
                          name="searchNoteServiceClientCorporateName"
                          description="Nome/Razão"
                          value={clientCorporateName}
                          maxLength={500}
                          disabled={false}
                          require={false}
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          onChange={(event) => setClientCorporateName(event.target.value)} />
                      </div>

                      { resultClientFilter && (resultClientFilter.length > 0) &&
                        <div style={(showMenu) ?
                            (width < 1170) ?
                              {display: "flex", flexDirection: "column", flexGrow: 1,
                                borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                marginTop: 30, marginBottom: 5}
                            :
                              {display: "flex", flexDirection: "row", flexGrow: 1,
                                borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                marginTop: 30, marginBottom: 4}
                          :
                            (width < 900) ?
                              {display: "flex", flexDirection: "column", flexGrow: 1,
                                borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                marginTop: 30, marginBottom: 5}
                            :
                              {display: "flex", flexDirection: "row", flexGrow: 1,
                                borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                marginTop: 30, marginBottom: 4}}>

                          <div style={(showMenu) ? 
                              (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 1170) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}
                            : 
                              (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                            <div style={(showMenu) ? 
                                (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                : (width < 720) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}
                              : 
                                (width < 480) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                              <div style={{display: "flex", flexGrow: 0, marginLeft: 15, minWidth: 50, maxWidth: 50}} />

                              <div style={showMenu ?
                                  (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                  : (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                :
                                  (width < 480) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                <label style={{fontWeight: 600}}>Código</label>
                              </div>
                            </div>
                            <div style={showMenu ?
                                (width < 480) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : (width < 1170) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}
                              :  
                                (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : (width < 900) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}}>
                              <label style={{fontWeight: 600}}>CPF/CNPJ</label>
                            </div>
                          </div>
                          <div style={showMenu ?
                              (width < 1170) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, minWidth: 180}
                            :
                              (width < 900) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, minWidth: 180}}>
                            <label style={{fontWeight: 600}}>Nome/Razão Social</label>
                          </div>
                        </div>
                      }

                      { resultClientFilter && resultClientFilter.map(item => (
                        <div key={item.CPF_CNPJ + "_" + item.CORPORATE_NAME} style={showMenu ?
                            (width < 1170) ?
                              {display: "flex", flexDirection: "column", flexGrow: 1,
                              backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                              borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                              marginTop: 4, minWidth: 180}
                            : 
                              {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                              backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                              borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                              marginTop: 4, minWidth: 180}
                          :
                            (width < 900) ?
                              {display: "flex", flexDirection: "column", flexGrow: 1,
                              backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                              borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                              marginTop: 4, minWidth: 180}
                            : 
                              {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                              backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                              borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                              marginTop: 4, minWidth: 180}}>

                          <div style={(showMenu) ? 
                              (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 1170) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}
                            : 
                              (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                            <div style={(showMenu) ? 
                                (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                : (width < 720) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}
                              : 
                                (width < 480) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                              <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 50, maxWidth: 50}}>
                                <input type="checkbox" checked={item.MARKET} onChange={ () => updateShowClient(item.ID) } />
                              </div>

                              <div style={showMenu ?
                                  (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                  : (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                :
                                  (width < 480) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                <label>{item.ID}</label>
                              </div>
                            </div>
                            <div style={showMenu ?
                                (width < 480) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : (width < 1170) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}
                              :  
                                (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : (width < 900) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}}>
                              <label>
                                { item.CPF_CNPJ.length === 11 &&
                                  item.CPF_CNPJ.substr(0,3) + "." + item.CPF_CNPJ.substr(4,3) + "." + item.CPF_CNPJ.substr(6,3) + "-" + 
                                    item.CPF_CNPJ.substr(9,2)
                                }

                                { item.CPF_CNPJ.length === 14 &&
                                  item.CPF_CNPJ.substr(0,2) + "." + item.CPF_CNPJ.substr(2,3) + "." + item.CPF_CNPJ.substr(5,3) + "/" + 
                                    item.CPF_CNPJ.substr(8,4) + "-" + item.CPF_CNPJ.substr(12,4)
                                }
                              </label>
                            </div>
                          </div>
                          <div style={showMenu ?
                              (width < 1170) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, minWidth: 180}
                            :
                              (width < 900) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, minWidth: 180}}>
                            <label>
                              {item.CORPORATE_NAME}
                            </label>
                          </div>
                        </div>
                        ))
                      }

                      { resultClientFilter && (resultClientFilter.length === 0) &&
                        <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", marginTop: 10}}>
                          <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>Nenhum registro encontrado.</label>
                        </div>
                      }

                    </div>
                  </div>
                }

                <div style={(showMenu) ? 
                    (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                  : 
                    (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                  <div style={showMenu ?
                      (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                    :
                      (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>
                    <b style={{marginBottom: 10}}>Data Lançamento</b>
                    
                    <div style={(width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                      <div style={(width < 420) ? {display: "flex", flexGrow: 1}
                        : {display: "flex", flexGrow: 1, flexBasis: 1}}>
                        <DataPicker
                          name="searchNoteServiceIniDate"
                          description="Inicial"
                          value={iniDate}
                          require={false}
                          onSelect={setFieldSelected}
                          onChange={(event => setIniDate(event))} 
                          intervalo={true}
                          inicial={true}
                          endDate={endDate} />
                      </div>

                      <div style={(width < 420) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 0, alignItems: "end", minWidth: 10, marginLeft: 5, marginRight: 5, marginBottom: 10}}>
                        <label style={{fontWeight: 600}}>à</label>
                      </div>

                      <div style={(width < 420) ? {display: "flex", flexGrow: 1}
                        : {display: "flex", flexGrow: 1, flexBasis: 1}}>
                        <DataPicker
                          name="searchNoteServiceEndDate"
                          description="Final"
                          value={endDate}
                          require={false}
                          onSelect={setFieldSelected}
                          onChange={(event => setEndDate(event))} 
                          intervalo={true}
                          inicial={false}
                          startDate={iniDate} />
                      </div>
                    </div>
                  </div>

                  <div style={showMenu ?
                      (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                      : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                    :
                      (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                      : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>
                    <b style={{marginBottom: 10}}>Data Transmissão</b>

                    <div style={(width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                      <div style={(width < 420) ? {display: "flex", flexGrow: 1}
                        : {display: "flex", flexGrow: 1, flexBasis: 1}}>
                        <DataPicker
                          name="searchNoteServiceTransmissionDateIni"
                          description="Inicial"
                          value={transmissionDateIni}
                          require={false}
                          onSelect={setFieldSelected}
                          onChange={(event) => setTransmissionDateIni(event)} 
                          intervalo={true}
                          inicial={true}
                          endDate={transmissionDateEnd} />
                      </div>

                      <div style={(width < 420) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 0, alignItems: "end", minWidth: 10, marginLeft: 5, marginRight: 5, marginBottom: 10}}>
                        <label style={{fontWeight: 600}}>à</label>
                      </div>

                      <div style={(width < 420) ? {display: "flex", flexGrow: 1}
                        : {display: "flex", flexGrow: 1, flexBasis: 1}}>
                        <DataPicker
                          name="searchNoteServiceTransmissionDateEnd"
                          description="Final"
                          value={transmissionDateEnd}
                          require={false}
                          onSelect={setFieldSelected}
                          onChange={(event) => setTransmissionDateEnd(event)} 
                          intervalo={true}
                          inicial={false}
                          startDate={transmissionDateIni} />
                      </div>
                    </div>
                  </div>
                </div>
                
                { showResult && 
                  <>

                    { result.OBJECT &&
                      <>
                        <div style={showMenu ?
                            (width < 930) ?
                              {display: "flex", flexDirection: "column", flexGrow: 1,
                              borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                              marginTop: 30, marginBottom: 4}
                            :
                              {display: "flex", flexDirection: "row", flexGrow: 1,
                              borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                              marginTop: 30, marginBottom: 4}
                          :
                            (width < 660) ?
                              {display: "flex", flexDirection: "column", flexGrow: 1,
                              borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                              marginTop: 30, marginBottom: 4}
                            :
                              {display: "flex", flexDirection: "row", flexGrow: 1,
                              borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                              marginTop: 30, marginBottom: 4}}>

                          <div style={showMenu ?
                              (width < 1690) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 1}
                            :
                              (width < 1410) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 1}}>

                            <div style={showMenu ?
                                (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : (width < 1690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}
                              :
                                (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : (width < 1410) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                              <div style={(width < 420) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}}>
                                <label style={{fontWeight: 600}}>Ambiente</label>
                              </div>

                              <div style={showMenu ?
                                  (width < 420) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : (width < 1690) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 130, maxWidth: 130}
                                :
                                  (width < 420) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : (width < 1410) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 130, maxWidth: 130}}>
                                <label style={{fontWeight: 600}}>Número</label>
                              </div>
                            </div>

                            <div style={showMenu ?
                                (width < 1490) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : (width < 1690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                              :
                                (width < 1220) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : (width < 1410) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                              <div style={showMenu ?
                                  (width < 870) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 1490) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : (width < 1690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                                :
                                  (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 1220) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : (width < 1410) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>

                                <div style={{display: "flex", flexGrow: 0, minWidth: 45, maxWidth: 45}}>
                                  <label style={{fontWeight: 600}}>Série</label>
                                </div>

                                <div style={showMenu ?
                                    (width < 380) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 870) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : (width < 1490) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                                  :
                                    (width < 380) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 480) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : (width < 1220) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>

                                  <div style={showMenu ?
                                      (width < 380) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : (width < 1490) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 90}
                                      : {display: "flex", flexGrow: 0, minWidth: 90, maxWidth: 90}
                                    :
                                      (width < 380) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : (width < 1220) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 90}
                                      : {display: "flex", flexGrow: 0, minWidth: 90, maxWidth: 90}}>
                                    <label style={{fontWeight: 600}}>Lancto.</label>
                                  </div>

                                  <div style={showMenu ?
                                      (width < 380) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : (width < 1490) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 90}
                                      : {display: "flex", flexGrow: 0, minWidth: 90, marginLeft: 10, maxWidth: 90}
                                    :
                                      (width < 380) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : (width < 1220) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 90}
                                      : {display: "flex", flexGrow: 0, minWidth: 90, marginLeft: 10, maxWidth: 90}}>
                                    <label style={{fontWeight: 600}}>Transm.</label>
                                  </div>
                                </div>
                              </div>

                              <div style={showMenu ?
                                  (width < 1490) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                  : {display: "flex", flexGrow: 1, wordBreak: "break-all", marginLeft: 10, minWidth: 180}
                                :
                                  (width < 1220) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                  : {display: "flex", flexGrow: 1, wordBreak: "break-all", marginLeft: 10, minWidth: 180}}>
                                <label style={{fontWeight: 600}}>Cliente</label>
                              </div>

                              <div style={showMenu ?
                                  (width < 380) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 1490) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                                :
                                  (width < 380) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 1220) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>

                                <div style={showMenu ?
                                    (width < 380) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : (width < 1490) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 110}
                                    : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110, marginLeft: 10}
                                  :
                                    (width < 380) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : (width < 1220) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 110}
                                    : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110, marginLeft: 10}}>
                                  <label style={{fontWeight: 600}}>Valor</label>
                                </div>

                                <div style={showMenu ?
                                    (width < 380) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : (width < 1490) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 170, marginLeft: 10}
                                    : {display: "flex", flexGrow: 0, minWidth: 170, maxWidth: 170, marginLeft: 10}
                                  :
                                    (width < 380) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : (width < 1220) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 170, marginLeft: 10}
                                    : {display: "flex", flexGrow: 0, minWidth: 170, maxWidth: 170, marginLeft: 10}}>
                                  <label style={{fontWeight: 600}}>Situação</label>
                                </div>
                              </div>
                            </div>
                          </div>

                          { ((showMenu && (width >= 930)) || (!showMenu && (width >= 660))) &&
                            <div style={{display: "flex", justifyContent: "flex-end", marginRight: 5, width: 45}} />
                          }

                        </div>

                        { result.OBJECT.map(item => (
                          <div key={item.ID} style={(showMenu) ?
                              (width < 930) ? {display: "flex", flexDirection: "column", flexGrow: 1,
                                backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5,
                                borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 4, paddingBottom: 4, marginTop: 4,
                                minWidth: 190}
                              :
                                {display: "flex", flexDirection: "row", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)",
                                borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5,
                                paddingTop: 4, paddingBottom: 4, marginTop: 4 }
                            :
                              (width < 660) ? {display: "flex", flexDirection: "column", flexGrow: 1,
                                backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5,
                                borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 4, paddingBottom: 4, marginTop: 4,
                                minWidth: 190}
                              :
                                {display: "flex", flexDirection: "row", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)",
                                borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5,
                                paddingTop: 4, paddingBottom: 4, marginTop: 4 }}>
                          
                            <div style={showMenu ?
                                (width < 1690) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 1}
                              :
                                (width < 1410) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 1}}>

                              <div style={showMenu ?
                                  (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 1690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}
                                :
                                  (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 1410) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                <div style={(width < 420) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}}>
                                  { String(item.ENVIRONMENT) === "1" && <label>Produção</label> }
                                  { String(item.ENVIRONMENT) === "2" && <label style={{fontWeight: 600, color: "red"}} >Homologação</label> }                                  
                                </div>

                                <div style={showMenu ?
                                    (width < 420) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : (width < 1690) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 130, maxWidth: 130}
                                  :
                                    (width < 420) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : (width < 1410) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 130, maxWidth: 130}}>
                                  <label>{item.NUMBER}</label>
                                </div>
                              </div>

                              <div style={showMenu ?
                                  (width < 1490) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 1690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                :
                                  (width < 1220) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 1410) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                                <div style={showMenu ?
                                    (width < 870) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 1490) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : (width < 1690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                                  :
                                    (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 1220) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : (width < 1410) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>

                                  <div style={{display: "flex", flexGrow: 0, minWidth: 45, maxWidth: 45}}>
                                    <label>{item.SERIES}</label>
                                  </div>

                                  <div style={showMenu ?
                                      (width < 380) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 870) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : (width < 1490) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                                    :
                                      (width < 380) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 480) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : (width < 1220) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>

                                    <div style={showMenu ?
                                        (width < 380) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : (width < 1490) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 90}
                                        : {display: "flex", flexGrow: 0, minWidth: 90, maxWidth: 90}
                                      :
                                        (width < 380) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : (width < 1220) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 90}
                                        : {display: "flex", flexGrow: 0, minWidth: 90, maxWidth: 90}}>
                                      { String(item.DATE).substr(8,2) + "/" +
                                        String(item.DATE).substr(5,2) + "/" +
                                          String(item.DATE).substr(0,4) }
                                    </div>

                                    <div style={showMenu ?
                                        (width < 380) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : (width < 1490) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 90}
                                        : {display: "flex", flexGrow: 0, minWidth: 90, marginLeft: 10, maxWidth: 90}
                                      :
                                        (width < 380) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : (width < 1220) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 90}
                                        : {display: "flex", flexGrow: 0, minWidth: 90, marginLeft: 10, maxWidth: 90}}>
                                      { item.TRANSMISSION_DATE !== "0001-01-01T00:00:00" ?
                                        String(item.TRANSMISSION_DATE).substr(8,2) + "/" +
                                          String(item.TRANSMISSION_DATE).substr(5,2) + "/" +
                                            String(item.TRANSMISSION_DATE).substr(0,4) : " - "
                                      }
                                    </div>
                                  </div>
                                </div>

                                <div style={showMenu ?
                                    (width < 1490) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                    : {display: "flex", flexGrow: 1, wordBreak: "break-all", marginLeft: 10, minWidth: 180}
                                  :
                                    (width < 1220) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                    : {display: "flex", flexGrow: 1, wordBreak: "break-all", marginLeft: 10, minWidth: 180}}>
                                  <label>{item.BILL_RECEIVE.CLIENT.CORPORATE_NAME}</label>
                                </div>

                                <div style={showMenu ?
                                    (width < 380) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 1490) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                                  :
                                    (width < 380) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 1220) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>

                                  <div style={showMenu ?
                                      (width < 380) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : (width < 1490) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 110}
                                      : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110, marginLeft: 10}
                                    :
                                      (width < 380) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : (width < 1220) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 110}
                                      : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110, marginLeft: 10}}>
                                    <label>{ formatNumeric(item.VALUE, 2)}</label>
                                  </div>

                                  <div style={showMenu ?
                                      (width < 380) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : (width < 1490) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 170, marginLeft: 10}
                                      : {display: "flex", flexGrow: 0, minWidth: 170, maxWidth: 170, marginLeft: 10}
                                    :
                                      (width < 380) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : (width < 1220) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 170, marginLeft: 10}
                                      : {display: "flex", flexGrow: 0, minWidth: 170, maxWidth: 170, marginLeft: 10}}>
                                    <label>
                                      { (item.SITUATION === 1) && "Lançada" }
                                      { (item.SITUATION === 2) && "Aguardando Retorno" }
                                      { (item.SITUATION === 3) && "Transmitida" }
                                      { (item.SITUATION === 4) && "Cancelada" }
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div style={showMenu ?
                                (width < 930) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10, marginBottom: 10}
                                : {display: "flex", justifyContent: "flex-end", marginRight: 5, width: 45}
                              : 
                                (width < 660) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10, marginBottom: 10}
                                : {display: "flex", justifyContent: "flex-end", marginRight: 5, width: 45}}>

                              <div style={{display: "flex", alignItems: "center", marginRight: 5}}>
                                <img 
                                  style={{height: 32, width: 32}}
                                  name="searchNoteService"
                                  src={image_select}
                                  alt="Selecionar"
                                  onClick={(() => setSelected(item))} />
                              </div>
                            </div>
                          </div>
                          ))
                        }

                        <SearchPagination 
                          pageCurrent={pageCurrent}
                          totalPage={totalPage}
                          recordPage={recordPage}
                          optionsRegPage={JSON.parse(optionsRegPage)}
                          pagePrevious={pagePrevious}
                          pageNext={pageNext}
                          setSelectedField={setFieldSelected}
                          handleRecordPage={handleRecordPage}
                          width={width} />
                      </>
                    }

                  </>
                }

              </div>
            </div>
          </div>
      }
    </div>
  )
}

export default SearchNoteService