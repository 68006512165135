import "./style.css"
import { useState, useEffect } from 'react'
import { Routes, Route, useNavigate } from "react-router-dom"
import Account from './Containers/Account'
import AccountGroup from "./Containers/AccountGroup"
import AccountPlan from './Containers/AccountPlan'
import AccountTransfer from './Containers/AccountTransfer'
import Accounting from './Containers/Accounting'
import Address from './Containers/Address'
import Agency from './Containers/Agency'
import Aliquot from './Containers/Aliquot'
import Anp from './Containers/Anp'
import Bank from './Containers/Bank'
import BilletLot from './Containers/BilletLot'
import BillPay from './Containers/BillPay'
import BillPayImport from "./Containers/BillPayImport"
import BillReceive from './Containers/BillReceive'
import BillReceiveImport from "./Containers/BillReceiveImport"
import BordereauPay from './Containers/BordereauPay'
import BordereauReceive from "./Containers/BordereauReceive"
import Box from './Containers/Box'
import Branch from './Containers/Branch'
import Carrier from './Containers/Carrier'
import Cest from './Containers/Cest'
import Cfop from './Containers/Cfop'
import City from './Containers/City'
import Client from './Containers/Client'
import ClientClass from './Containers/ClientClass'
import ClientRoute from './Containers/ClientRoute'
import ClientType from './Containers/ClientType'
import Company from './Containers/Company'
import Country from './Containers/Country'
import CstCofins from './Containers/CstCofins'
import CstCsosn from './Containers/CstCsosn'
import CstIpi from './Containers/CstIpi'
import CstPis from './Containers/CstPis'
import DapPay from './Containers/DapPay'
import DapReceive from './Containers/DapReceive'
import Department from './Containers/Department'
import DigitalCertificate from './Containers/DigitalCertificate'
import DocumentType from './Containers/DocumentType'
import Employee from './Containers/Employee'
import Establishment from './Containers/Establishment'
import Finisher from './Containers/Finisher'
import FisClass from './Containers/FisClass'
import GenerateQrCodeTable from './Containers/GenerateQrCodeTable'
import LaunchRestaurant from './Containers/LaunchRestaurant'
import Login from './Containers/Login'
import Main from './Containers/Main'
import Manufacturer from './Containers/Manufacturer'
import MentesPensam from './Containers/MentesPensam'
import Neighborhood from './Containers/Neighborhood'
import Ncm from './Containers/Ncm'
import NfModel from './Containers/NfModel'
import NoteService from './Containers/NoteService'
import PaymentForm from './Containers/PaymentForm'
import PriceTable from './Containers/PriceTable'
import PrinterSection from './Containers/PrinterSection'
import Product from './Containers/Product'
import RegisterTable from './Containers/RegisterTable'
import ReportAccountBalance from './Containers/ReportAccountBalance'
import ReportBankStatement from './Containers/ReportBankStatement'
import ReportBillPay from './Containers/ReportBillPay'
import ReportBillReceive from './Containers/ReportBillReceive'
import ReportExpirationClientBillet from './Containers/ReportExpirationClientBillet'
import RhPosition from "./Containers/RhPosition"
import RhSection from './Containers/RhSection'
import RhShift from './Containers/RhShift'
// import Series from './Containers/Series'
import SearchPriceMarkdown from './Containers/PriceMarkdown/Search'
import ServiceCnae from './Containers/ServiceCnae'
import State from './Containers/State'
import Stock from './Containers/Stock'
import Supplier from './Containers/Supplier'
import TaxDefault from './Containers/TaxDefault'
import Terminal from './Containers/Terminal'
import TerminalSelect from './Containers/Terminal/Select'
import TypeTaxOperation from './Containers/TypeTaxOperation'
import Unit from './Containers/Unit'

import TableCenter from './Containers/TableCenter'
import TableItens from './Containers/TableItens'
import TableItensTouch from './Containers/TableItensTouch'

const App = () => {
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)
  
  const [authenticated, setAuthenticated] = useState(false)
  const [databasePath, setDatabasePath] = useState("")
  const [databaseType, setDatabaseType] = useState(1)
  const [system, setSystem] = useState(false)
  
  const [jsonCompany, setJsonCompany] = useState("")
  const [jsonLogin, setJsonLogin] = useState("")

  const [noteService, setNoteService] = useState(null)
  const [terminal, setTerminal] = useState({})

  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
    }

    window.addEventListener("resize", handleWindowResize)
    return () => window.removeEventListener("resize", handleWindowResize)
  }, []);

  const logoff = () => {
    setDatabaseType(1)
    setDatabasePath("")
    setSystem(false)
    setJsonCompany("")
    setJsonLogin("")

    setAuthenticated(false)
  }

  const logon = (databaseType, databasePath, system, jsonCompany, jsonLogin) => {
    navigate("/")

    setDatabaseType(databaseType)
    setDatabasePath(databasePath)
    setSystem(system)
    setJsonCompany(jsonCompany)
    setJsonLogin(jsonLogin)

    setAuthenticated(true)
  }

  const returnMain = () => {
    navigate("/")
  }

  const selectNoteService = (note, bill) => {
    note.BILL_RECEIVE = bill
    setNoteService(note)

    navigate("/noteService")
  }

  const selectTerminal = (term) => {    
    localStorage.setItem("terminal", JSON.stringify(term))
    navigate("/")
  }

  const terminalSelect = () => {
    navigate("/terminalSelect")
  }  

  const navigate = useNavigate()

  return (
    <div style={{display: "flex", flexGrow: 1, flexDirection: "column", fontsize: 14}}>

      { !authenticated && <Login logon={logon} height={height} width={width} /> }

      { authenticated &&
        <Routes>
          <Route path="/" element={<Main databaseType={databaseType} databasePath={databasePath} jsonCompany={jsonCompany}
            jsonLogin={jsonLogin} system={system} height={height} width={width} terminalSelect={terminalSelect} logoff={logoff} />} />

          <Route path="*" element={<Main databaseType={databaseType} databasePath={databasePath} jsonCompany={jsonCompany}
            jsonLogin={jsonLogin} system={system} height={height} width={width} terminalSelect={terminalSelect} logoff={logoff} />} />

          <Route path="account" element={<Account modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="accountGroup" element={<AccountGroup modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="accountPlan" element={<AccountPlan modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="accountTransfer" element={<AccountTransfer modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="accounting" element={<Accounting modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="address" element={<Address modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="agency" element={<Agency modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="aliquot" element={<Aliquot modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="anp" element={<Anp modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="bank" element={<Bank modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="box" element={<Box modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="branch" element={<Branch modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="billetLot" element={<BilletLot databaseType={databaseType} databasePath={databasePath} jsonCompany={jsonCompany} jsonLogin={jsonLogin}
            system={system} setReturn={() => navigate("/")} logoff={logoff} height={height} width={width} />} />

          <Route path="billPay" element={<BillPay modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system}  setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="billPayImport" element={<BillPayImport databaseType={databaseType} databasePath={databasePath} jsonCompany={jsonCompany} 
            jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width} logoff={logoff} />} />

          <Route path="billReceive" element={<BillReceive modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system}  selectNoteService={selectNoteService} setReturn={() => navigate("/")}
            height={height} width={width} logoff={logoff} />} />

          <Route path="billReceiveImport" element={<BillReceiveImport databaseType={databaseType} databasePath={databasePath} jsonCompany={jsonCompany} 
            jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width} logoff={logoff} />} />

          <Route path="bordereauPay" element={<BordereauPay modeSearch={false} databaseType={databaseType} databasePath={databasePath} 
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width} 
            logoff={logoff} />} />

          <Route path="bordereauReceive" element={<BordereauReceive modeSearch={false} databaseType={databaseType} databasePath={databasePath} 
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width} 
            logoff={logoff} />} />

          <Route path="cest" element={<Cest modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="city" element={<City modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="client" element={<Client modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="clientClass" element={<ClientClass modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="clientRoute" element={<ClientRoute modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="clientType" element={<ClientType modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="serviceCnae" element={<ServiceCnae modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />      

          <Route path="carrier" element={<Carrier modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="cfop" element={<Cfop modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="company" element={<Company modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="country" element={<Country modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="cstCofins" element={<CstCofins modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="cstCsosn" element={<CstCsosn modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="cstIpi" element={<CstIpi modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="cstPis" element={<CstPis modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="dapPay" element={<DapPay databaseType={databaseType} databasePath={databasePath} jsonCompany={jsonCompany} 
            jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width} logoff={logoff} />} />

          <Route path="dapReceive" element={<DapReceive databaseType={databaseType} databasePath={databasePath} jsonCompany={jsonCompany} 
            jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width} logoff={logoff} />} />

          <Route path="department" element={<Department modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="digitalCertificate" element={<DigitalCertificate databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="documentType" element={<DocumentType modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="employee" element={<Employee modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="establishment" element={<Establishment modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="finisher" element={<Finisher modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="fisClass" element={<FisClass modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="generateQrCodeTable" element={<GenerateQrCodeTable modeSearch={false} databaseType={databaseType} 
            databasePath={databasePath} jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} 
            height={height} width={width} logoff={logoff} />} />

          <Route path="launchRestaurant" element={<LaunchRestaurant databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="manufacturer" element={<Manufacturer modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="mentesPensam" element={<MentesPensam databaseType={databaseType} databasePath={databasePath} jsonCompany={jsonCompany}
            jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width} logoff={logoff} />} />

          <Route path="ncm" element={<Ncm databaseType={databaseType} databasePath={databasePath} jsonCompany={jsonCompany}
            jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width} logoff={logoff} />} />

          <Route path="neighborhood" element={<Neighborhood modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="nfModel" element={<NfModel modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="noteService" element={<NoteService modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} noteService={noteService} setNoteService={setNoteService} 
            setReturn={() => navigate("/")} height={height} width={width} logoff={logoff} />} />

          <Route path="paymentForm" element={<PaymentForm modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="priceTable" element={<PriceTable modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="printerSection" element={<PrinterSection modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="product" element={<Product modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="registerTable" element={<RegisterTable modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="reportAccountBalance" element={<ReportAccountBalance databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="reportBankStatement" element={<ReportBankStatement databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="reportBillPay" element={<ReportBillPay databaseType={databaseType} databasePath={databasePath} jsonCompany={jsonCompany} 
            jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width} logoff={logoff} />} />

          <Route path="reportBillReceive" element={<ReportBillReceive databaseType={databaseType} databasePath={databasePath} jsonCompany={jsonCompany} 
            jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width} logoff={logoff} />} />

          <Route path="reportExpirationClientBillet" element={<ReportExpirationClientBillet databaseType={databaseType} databasePath={databasePath} 
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width} 
            logoff={logoff} />} />

          <Route path="searchPriceMarkdown" element={<SearchPriceMarkdown databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="rhPosition" element={<RhPosition modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="rhSection" element={<RhSection modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="rhShift" element={<RhShift modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          {/* <Route path="series" element={<Series modeSearch={false} process={1} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} /> */}

          <Route path="state" element={<State modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="stock" element={<Stock modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="supplier" element={<Supplier modeSearch={false} process={1} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="taxDefault" element={<TaxDefault databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="terminal" element={<Terminal modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} selectTerminal={selectTerminal} setReturn={() => navigate("/")} 
            height={height} width={width} logoff={logoff} />} />

          <Route path="terminalSelect" element={<TerminalSelect databaseType={databaseType} databasePath={databasePath} jsonCompany={jsonCompany} 
            jsonLogin={jsonLogin} system={system} terminal={terminal} setTerminal={setTerminal} setSelected={selectTerminal} 
            setReturnMain={returnMain} logoff={logoff} height={height} width={width} />} />            

          <Route path="typeTaxOperation" element={<TypeTaxOperation modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          <Route path="unit" element={<Unit modeSearch={false} databaseType={databaseType} databasePath={databasePath}
            jsonCompany={jsonCompany} jsonLogin={jsonLogin} system={system} setReturn={() => navigate("/")} height={height} width={width}
            logoff={logoff} />} />

          {/*
          <Route path="tableCenter" element={<TableCenter typeDatabase={tipoBanco} pathDatabase={caminhoBanco} jsonCompany={jsonFilial} jsonLogin={jsonLogin} system={sistema} setReturn={() => navigate("/")} logoff={logoff} />} />
          <Route path="tableItens/:numberTable" element={<TableItens typeDatabase={tipoBanco} pathDatabase={caminhoBanco} jsonCompany={jsonFilial} jsonLogin={jsonLogin} system={sistema} setReturn={() => navigate("/")} logoff={logoff} />} />
          <Route path="tableItensTouch/:numberTable" element={<TableItensTouch typeDatabase={tipoBanco} pathDatabase={caminhoBanco} jsonCompany={jsonFilial} jsonLogin={jsonLogin} system={sistema} width={width} height={height} setReturn={() => navigate("/")} logoff={logoff} />} />    
          */}
        </Routes>
      }
    </div>
  )
}
 
export default App