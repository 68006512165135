import React, { useState, useEffect } from "react"
import Input from "../../Components/Input"
import Select from "../../Components/Select"
import image_back from "../../Images/Buttons/previous.png"
import image_save from "../../Images/Buttons/save.png"

const CompanyBank = ({jsonCompanyBank, save, back, showMenu, width}) => {
  const [code, setCode] = useState("")
  const [companyCode, setCompanyCode] = useState("")
  const [type, setType] = useState(0)
  const [login, setLogin] = useState("")
  const [password, setPassword] = useState("")
  const [token, setToken] = useState("")
  const [loginHomologation, setLoginHomologation] = useState("")
  const [passwordHomologation, setPasswordHomologation] = useState("")
  const [tokenHomologation, setTokenHomologation] = useState("")

  useEffect(() => {
    if (jsonCompanyBank.ID !== undefined) {

      console.log(jsonCompanyBank)
      setCode(jsonCompanyBank.ID)
      setCompanyCode(jsonCompanyBank.COMPANY.ID)
      setType(jsonCompanyBank.TYPE)
      setLogin(jsonCompanyBank.LOGIN)
      setPassword(jsonCompanyBank.PASSWORD)
      setToken(jsonCompanyBank.TOKEN)
      setLoginHomologation(jsonCompanyBank.LOGIN_HOMOLOGATION)
      setPasswordHomologation(jsonCompanyBank.PASSWORD_HOMOLOGATION)
      setTokenHomologation(jsonCompanyBank.TOKEN_HOMOLOGATION)
    }
    else setCompanyCode(jsonCompanyBank.COMPANY.ID)

    document.getElementById("companyBankType") && document.getElementById("companyBankType").focus()

  }, [jsonCompanyBank])

  const callSave = () => {
    let json = "{"
    if (((code !== undefined) && (code !== "")) && (code !== 0)) json += " \"ID\": "+ code + ","
    json += " \"COMPANY\": { \"ID\": " + companyCode + " }"

    if (type !== undefined) {
      if (String(type) === "1") json += ", \"TYPE\": 1"
      else if (String(type) === "2") json += ", \"TYPE\": 2"
      else json += ", \"TYPE\": 0"
    } else json += ", \"TYPE\": 0"

    json += ", \"LOGIN\": \""+ login +"\"" +
    ", \"PASSWORD\": \""+ password +"\"" +
    ", \"TOKEN\": \""+ token +"\"" +
    ", \"LOGIN_HOMOLOGATION\": \""+ loginHomologation +"\"" +
    ", \"PASSWORD_HOMOLOGATION\": \""+ passwordHomologation +"\"" +
    ", \"TOKEN_HOMOLOGATION\": \""+ tokenHomologation +"\" }"

    save(json)
  }

  const setFieldSelected = () => {
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>
      <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5,
          marginLeft: 5, width: 147}} onClick={callSave}>
          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
            paddingBottom: 5, paddingLeft: 10}}>
            <img style={{height: 32, width: 32}} src={image_save} alt="Salvar"/>
          </div>
          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Salvar</label>
          </div>
        </div>
        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5,
          marginLeft: 5, width: 147}} onClick={() => back()}>
          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
            paddingBottom: 5, paddingLeft: 10}}>
            <img style={{height: 32, width: 32}} src={image_back} alt="Retornar"/>
          </div>
          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
          </div>
        </div>
      </div>

      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
      </div>
      
      <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0, marginTop: 10}
        : {paddingLeft: 20, paddingRight: 20, marginTop: 10}}>

        <div style={(width < 450) ? 
            {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 0, paddingRight: 0, marginTop: 10}
          :
            {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}}>
          <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
            <Select
              name="companyBankType"
              description="Tipo"
              value={type || 0}
              require={false}
              options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                ", {\"ID\": 1, \"VALUE\": \"Mentes que Pensam\" }, {\"ID\": 2, \"VALUE\": \"Banco do Brasil\" }] }")}
              image={false}
              typeInput={2}
              onSelect={setFieldSelected}
              set={setType} />
          </div>
        </div>

        <div style={(width < 450) ?
            {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 0, paddingRight: 0, marginTop: 20}
          :
            {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 20}}>
          <label style={{fontWeight: 600}}>Produção:</label>
        </div>        

        <div style={(width < 450) ?
            {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 0, paddingRight: 0, marginTop: 10}
          :
            {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}}>
          <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
            <Input
                type="text"
                name="companyBankToken"
                description="Token"
                value={token}
                maxLength={2000}
                disabled={false}
                require={false}
                image={false}
                typeInput={2}
                onSelect={setFieldSelected}
                onChange={(event) => setToken(event.target.value)} />
          </div>
        </div>

        <div style={showMenu ?
            (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 0, paddingRight: 0, marginTop: 10}
            : (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}
            : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}
          :
            (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 0, paddingRight: 0, marginTop: 10}
            : (width < 680) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}
            : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}}>
            <div style={showMenu ?
                (width < 980) ? {display: "flex", flexGrow: 1, minWidth: 180}
                : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
              :
                (width < 680) ? {display: "flex", flexGrow: 1, minWidth: 180}
                : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
              <Input
                type="text"
                name="companyBankLogin"
                description="Login"
                value={login}
                maxLength={18}
                disabled={false}
                require={false}
                image={false}
                typeInput={2}
                onSelect={setFieldSelected}
                onChange={(event) => setLogin(event.target.value)} /> 
            </div>
            <div style={showMenu ?
                (width < 980) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
              :
                (width < 680) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}}>
              <Input
                type="text"
                name="companyBankPassword"
                description="Senha"
                value={password}
                maxLength={10}
                disabled={false}
                require={false}
                image={false}
                typeInput={2}
                onSelect={setFieldSelected}
                onChange={(event) => setPassword(event.target.value)} /> 
            </div>
        </div>

        <div style={(width < 450) ? 
            {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 0, paddingRight: 0, marginTop: 20}
          :
            {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 20}}>
          <label style={{fontWeight: 600}}>Homologação:</label>
        </div>

        <div style={(width < 450) ? 
            {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 0, paddingRight: 0, marginTop: 10}
          :
            {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}}>
          <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
            <Input
                type="text"
                name="companyBankTokenHomologation"
                description="Token"
                value={tokenHomologation}
                maxLength={2000}
                disabled={false}
                require={false}
                image={false}
                typeInput={2}
                onSelect={setFieldSelected}
                onChange={(event) => setTokenHomologation(event.target.value)} />
          </div>
        </div>

        <div style={showMenu ?
            (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 0, paddingRight: 0, marginTop: 10}
            : (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}
            : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}
          :
            (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 0, paddingRight: 0, marginTop: 10}
            : (width < 680) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}
            : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}}>
            <div style={showMenu ?
                (width < 980) ? {display: "flex", flexGrow: 1, minWidth: 180}
                : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
              :
                (width < 680) ? {display: "flex", flexGrow: 1, minWidth: 180}
                : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
              <Input
                type="text"
                name="companyBankLoginHomologation"
                description="Login"
                value={loginHomologation}
                maxLength={18}
                disabled={false}
                require={false}
                image={false}
                typeInput={2}
                onSelect={setFieldSelected}
                onChange={(event) => setLoginHomologation(event.target.value)} /> 
            </div>
            <div style={showMenu ?
                (width < 980) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
              :
                (width < 680) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}}>
              <Input
                type="text"
                name="companyBankPasswordHomologation"
                description="Senha"
                value={passwordHomologation}
                maxLength={10}
                disabled={false}
                require={false}
                image={false}
                typeInput={2}
                onSelect={setFieldSelected}
                onChange={(event) => setPasswordHomologation(event.target.value)} /> 
            </div>
        </div>     
      </div>
    </div>
  )
}

export default CompanyBank